*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline:  auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  font-family: InterNormal, system-ui, sans-serif;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-\[10\%\] {
  top: 10%;
}

.left-0 {
  left: 0;
}

.left-\[30px\] {
  left: 30px;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.left-2 {
  left: .5rem;
}

.bottom-0 {
  bottom: 0;
}

.right-4 {
  right: 1rem;
}

.z-\[1\] {
  z-index: 1;
}

.z-10 {
  z-index: 10;
}

.z-50 {
  z-index: 50;
}

.z-\[60\] {
  z-index: 60;
}

.z-\[2\] {
  z-index: 2;
}

.m-1 {
  margin: .25rem;
}

.m-3\.5 {
  margin: .875rem;
}

.m-3 {
  margin: .75rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-\[16px\] {
  margin-left: 16px;
}

.mt-0 {
  margin-top: 0;
}

.ml-3 {
  margin-left: .75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.ml-\[-13px\] {
  margin-left: -13px;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-fit {
  height: fit-content;
}

.h-auto {
  height: auto;
}

.h-\[109px\] {
  height: 109px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-10 {
  height: 2.5rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-\[40\] {
  height: 40px;
}

.h-\[48\] {
  height: 48px;
}

.h-\[32\] {
  height: 32px;
}

.h-\[242px\] {
  height: 242px;
}

.h-\[25px\] {
  height: 25px;
}

.h-\[14\] {
  height: 14px;
}

.h-\[30\] {
  height: 30px;
}

.max-h-full {
  max-height: 100%;
}

.max-h-\[90\%\] {
  max-height: 90%;
}

.max-h-screen {
  max-height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-\[24px\] {
  min-height: 24px;
}

.min-h-\[64px\] {
  min-height: 64px;
}

.w-full {
  width: 100%;
}

.w-\[160px\] {
  width: 160px;
}

.w-auto {
  width: auto;
}

.w-\[390px\] {
  width: 390px;
}

.w-10 {
  width: 2.5rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-\[40\] {
  width: 40px;
}

.w-0 {
  width: 0;
}

.w-\[48\] {
  width: 48px;
}

.w-\[100\%\] {
  width: 100%;
}

.w-\[32\] {
  width: 32px;
}

.w-\[25px\] {
  width: 25px;
}

.w-\[14\] {
  width: 14px;
}

.w-1 {
  width: .25rem;
}

.min-w-\[160px\] {
  min-width: 160px;
}

.min-w-full {
  min-width: 100%;
}

.max-w-none {
  max-width: none;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[58\%\] {
  max-width: 58%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-\[160px\] {
  max-width: 160px;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-\[100px\] {
  max-width: 100px;
}

.max-w-\[70px\] {
  max-width: 70px;
}

.max-w-\[200px\] {
  max-width: 200px;
}

.max-w-\[90\%\] {
  max-width: 90%;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-\[28rem\] {
  max-width: 28rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.shrink {
  flex-shrink: 1;
}

.flex-grow, .grow {
  flex-grow: 1;
}

.basis-0 {
  flex-basis: 0;
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.resize-none {
  resize: none;
}

.list-outside {
  list-style-position: outside;
}

.list-decimal {
  list-style-type: decimal;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-3 {
  gap: .75rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-5 {
  gap: 1.25rem;
}

.self-end {
  align-self: flex-end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-\[5px\] {
  border-radius: 5px;
}

.rounded {
  border-radius: 15px;
}

.rounded-\[30\] {
  border-radius: 30px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-\[16\] {
  border-radius: 16px;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.border-\[1px\] {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-b-\[1px\] {
  border-bottom-width: 1px;
}

.border-dark-blue {
  --tw-border-opacity: 1;
  border-color: rgb(0 39 51 / var(--tw-border-opacity) );
}

.border-\[\#F7D152\] {
  --tw-border-opacity: 1;
  border-color: rgb(247 209 82 / var(--tw-border-opacity) );
}

.border-\[\#EA3324\] {
  --tw-border-opacity: 1;
  border-color: rgb(234 51 36 / var(--tw-border-opacity) );
}

.border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.border-\[\#eaeaea\] {
  --tw-border-opacity: 1;
  border-color: rgb(234 234 234 / var(--tw-border-opacity) );
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity) );
}

.bg-\[\#00171f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 23 31 / var(--tw-bg-opacity) );
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity) );
}

.bg-dark-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(0 39 51 / var(--tw-bg-opacity) );
}

.bg-\[\#F7D152\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 209 82 / var(--tw-bg-opacity) );
}

.bg-\[\#EA3324\] {
  --tw-bg-opacity: 1;
  background-color: rgb(234 51 36 / var(--tw-bg-opacity) );
}

.bg-\[\#fafbfb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 251 251 / var(--tw-bg-opacity) );
}

.bg-\[\#072b36\] {
  --tw-bg-opacity: 1;
  background-color: rgb(7 43 54 / var(--tw-bg-opacity) );
}

.bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity) );
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity) );
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity) );
}

.bg-light-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(196 216 255 / var(--tw-bg-opacity) );
}

.bg-light-pink {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 251 / var(--tw-bg-opacity) );
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity) );
}

.bg-auth {
  background-image: url("auth-bg.560e648c.jpg");
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops) );
}

.from-light-pink {
  --tw-gradient-from: #ffe4fb;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(255, 228, 251, 0));
}

.to-dark-blue {
  --tw-gradient-to: #002733;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-white {
  fill: #fff;
}

.fill-dark-blue {
  fill: #002733;
}

.object-cover {
  object-fit: cover;
}

.p-10 {
  padding: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-2 {
  padding: .5rem;
}

.p-8 {
  padding: 2rem;
}

.p-1 {
  padding: .25rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pt-\[2px\] {
  padding-top: 2px;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[5\%\] {
  padding-top: 5%;
}

.pt-\[8\%\] {
  padding-top: 8%;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pl-\[25\] {
  padding-left: 25px;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-\[50px\] {
  padding-bottom: 50px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.font-interbold {
  font-family: InterBold, sans-serif;
}

.font-interlight {
  font-family: InterLight, sans-serif;
}

.font-intermedium {
  font-family: InterMedium, sans-serif;
}

.font-pprightgothic {
  font-family: PPRightGothic, sans-serif;
}

.font-internormal {
  font-family: InterNormal, sans-serif;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[10px\] {
  font-size: 10px;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[12px\] {
  font-size: 12px;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-\[11px\] {
  font-size: 11px;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.leading-tight {
  line-height: 1.25;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-none {
  line-height: 1;
}

.tracking-widest {
  letter-spacing: .1em;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity) );
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity) );
}

.text-dark-blue, .text-\[\#002733\] {
  --tw-text-opacity: 1;
  color: rgb(0 39 51 / var(--tw-text-opacity) );
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity) );
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity) );
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity) );
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity) );
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity) );
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity) );
}

.underline {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.line-through {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.opacity-40 {
  opacity: .4;
}

.\!opacity-50 {
  opacity: .5 !important;
}

.opacity-\[0\.5\] {
  opacity: .5;
}

.opacity-\[0\.2\] {
  opacity: .2;
}

.opacity-100 {
  opacity: 1;
}

.shadow-default {
  --tw-shadow: 0px 2px 21px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0px 2px 21px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, .03)) drop-shadow(0 8px 5px rgba(0, 0, 0, .08));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[max-height\] {
  transition-property: max-height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: PPRightGothic;
  src: url("PPRightGothic-Medium.92628248.otf");
}

@font-face {
  font-family: InterLight;
  src: url("Inter-Light.490626be.ttf");
}

@font-face {
  font-family: InterNormal;
  src: url("Inter-Regular.7cfebe77.ttf");
}

@font-face {
  font-family: InterMedium;
  src: url("Inter-Medium.775d466f.ttf");
}

@font-face {
  font-family: InterBold;
  src: url("Inter-Bold.88a04ef3.ttf");
}

.first-letter\:uppercase:first-letter {
  text-transform: uppercase;
}

.placeholder\:text-sm::-ms-input-placeholder {
  font-size: .875rem;
  line-height: 1.25rem;
}

.placeholder\:text-sm::placeholder {
  font-size: .875rem;
  line-height: 1.25rem;
}

.last\:pb-0:last-child {
  padding-bottom: 0;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-\[\#00171f\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 23 31 / var(--tw-bg-opacity) );
}

.hover\:underline:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.focus\:border-\[\#eaeaea\]:focus {
  --tw-border-opacity: 1;
  border-color: rgb(234 234 234 / var(--tw-border-opacity) );
}

.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity) );
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-gray-500:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity) );
}

.group:hover .group-hover\:mr-2\.5 {
  margin-right: .625rem;
}

.group:hover .group-hover\:mr-2 {
  margin-right: .5rem;
}

.group:hover .group-hover\:block {
  display: block;
}

.group:hover .group-hover\:w-\[120px\] {
  width: 120px;
}

.group:hover .group-hover\:justify-start {
  justify-content: flex-start;
}

@media (min-width: 640px) {
  .sm\:ml-\[66px\] {
    margin-left: 66px;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-\[50\%\] {
    width: 50%;
  }

  .sm\:flex-initial {
    flex: 0 auto;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

@media (min-width: 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:whitespace-normal {
    white-space: normal;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:pl-9 {
    padding-left: 2.25rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 1024px) {
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:mr-2\.5 {
    margin-right: .625rem;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-3 {
    margin-top: .75rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:mt-1 {
    margin-top: .25rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[72px\] {
    height: 72px;
  }

  .lg\:min-h-fit {
    min-height: fit-content;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-\[180px\] {
    width: 180px;
  }

  .lg\:w-\[72px\] {
    width: 72px;
  }

  .lg\:w-\[120px\] {
    width: 120px;
  }

  .lg\:max-w-\[250px\] {
    max-width: 250px;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:py-\[20px\] {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:text-\[12px\] {
    font-size: 12px;
  }

  .lg\:leading-none {
    line-height: 1;
  }
}

@media (min-width: 1280px) {
  .xl\:hidden {
    display: none;
  }

  .xl\:min-w-\[320px\] {
    min-width: 320px;
  }

  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
}

/*# sourceMappingURL=index.0347b776.css.map */
